import { useLocation, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import dm from "./theme/enc";
import Header from "./pages/components/Header";
import Footer from "./pages/components/Footer";
import Lottery from "./pages/games/Lottery";
import Fishing from "./pages/games/Fishing";
import Casino from "./pages/games/Casino";
import Pvp from "./pages/games/Pvp";
import Sports from "./pages/games/Sports";
import Slot from "./pages/games/Slot";
import Register from "./pages/register/Register";
import Login from "./pages/register/Login";
import Download from "./pages/download/Download";
import Home from "./pages/home/Home";
import Promotion from "./pages/promo/Promotion";

import ListXS from "./pages/game/Xoso";
//XSMB
import MBDedb from "./pages/game/XSMB/Dedb";
import MBDedaudb from "./pages/game/XSMB/Dedaudb";
import MBDe1 from "./pages/game/XSMB/De1";
import MBDedau1 from "./pages/game/XSMB/Dedau1";
import MBDegiai7 from "./pages/game/XSMB/Degiai7";
import MBLo2 from "./pages/game/XSMB/Lo2";
import MBLo2so1k from "./pages/game/XSMB/Lo2so1k";
import MBLo2dau from "./pages/game/XSMB/Lo2dau";
import MBLo3 from "./pages/game/XSMB/Lo3";
import MBLo4 from "./pages/game/XSMB/Lo4";
import MBLoxien2 from "./pages/game/XSMB/Loxien2";
import MBLoxien3 from "./pages/game/XSMB/Loxien3";
import MBLoxien4 from "./pages/game/XSMB/Loxien4";
import MBTruotxien4 from "./pages/game/XSMB/Truotxien4";
import MBTruotxien8 from "./pages/game/XSMB/Truotxien8";
import MBTruotxien10 from "./pages/game/XSMB/Truotxien10";
import MB3cangdb from "./pages/game/XSMB/3cangdb";
import MB3cang1 from "./pages/game/XSMB/3cang1";
import MB3cangdau from "./pages/game/XSMB/3cangdau";
import MB3cangdauduoi from "./pages/game/XSMB/3cangdauduoi";
import MB4cangdb from "./pages/game/XSMB/4cangdb";
import MBdau from "./pages/game/XSMB/Dau";
import MBduoi from "./pages/game/XSMB/Duoi";
//XSMT
import MTDedb from "./pages/game/XSMT/Dedb";
import MTDedaudb from "./pages/game/XSMT/Dedaudb";
import MTDe1 from "./pages/game/XSMT/De1";
import MTDedau1 from "./pages/game/XSMT/Dedau1";
import MTDegiai7 from "./pages/game/XSMT/Degiai7";
import MTDedauduoi from "./pages/game/XSMT/Dedauduoi";
import MTLo2 from "./pages/game/XSMT/Lo2";
import MTLo2so1k from "./pages/game/XSMT/Lo2so1k";
import MTLo2dau from "./pages/game/XSMT/Lo2dau";
import MTLo3 from "./pages/game/XSMT/Lo3";
import MTLo4 from "./pages/game/XSMT/Lo4";
import MTLoxien2 from "./pages/game/XSMT/Loxien2";
import MTLoxien3 from "./pages/game/XSMT/Loxien3";
import MTLoxien4 from "./pages/game/XSMT/Loxien4";
import MTTruotxien4 from "./pages/game/XSMT/Truotxien4";
import MTTruotxien8 from "./pages/game/XSMT/Truotxien8";
import MTTruotxien10 from "./pages/game/XSMT/Truotxien10";
import MT3cangdb from "./pages/game/XSMT/3cangdb";
import MT3cang1 from "./pages/game/XSMT/3cang1";
import MT3cangdau from "./pages/game/XSMT/3cangdau";
import MT3cangdauduoi from "./pages/game/XSMT/3cangdauduoi";
import MT4cangdb from "./pages/game/XSMT/4cangdb";
import MTdau from "./pages/game/XSMT/Dau";
import MTduoi from "./pages/game/XSMT/Duoi";
//XSMN
import MNDedb from "./pages/game/XSMN/Dedb";
import MNDedaudb from "./pages/game/XSMN/Dedaudb";
import MNDe1 from "./pages/game/XSMN/De1";
import MNDedau1 from "./pages/game/XSMN/Dedau1";
import MNDegiai7 from "./pages/game/XSMN/Degiai7";
import MNDedauduoi from "./pages/game/XSMN/Dedauduoi";
import MNLo2 from "./pages/game/XSMN/Lo2";
import MNLo2so1k from "./pages/game/XSMN/Lo2so1k";
import MNLo2dau from "./pages/game/XSMN/Lo2dau";
import MNLo3 from "./pages/game/XSMN/Lo3";
import MNLo4 from "./pages/game/XSMN/Lo4";
import MNLoxien2 from "./pages/game/XSMN/Loxien2";
import MNLoxien3 from "./pages/game/XSMN/Loxien3";
import MNLoxien4 from "./pages/game/XSMN/Loxien4";
import MNTruotxien4 from "./pages/game/XSMN/Truotxien4";
import MNTruotxien8 from "./pages/game/XSMN/Truotxien8";
import MNTruotxien10 from "./pages/game/XSMN/Truotxien10";
import MN3cangdb from "./pages/game/XSMN/3cangdb";
import MN3cang1 from "./pages/game/XSMN/3cang1";
import MN3cangdau from "./pages/game/XSMN/3cangdau";
import MN3cangdauduoi from "./pages/game/XSMN/3cangdauduoi";
import MN4cangdb from "./pages/game/XSMN/4cangdb";
import MNdau from "./pages/game/XSMN/Dau";
import MNduoi from "./pages/game/XSMN/Duoi";

function App() {
    const location = useLocation();
    const encodedDo = "YldWbllUWTRMbUpsZEE9PQ=="
    const currentDo = window.location.hostname;
    const encodedCurrentDo = btoa(btoa(currentDo));
    const isAllowedDo = encodedCurrentDo === encodedDo;
    return (
        <div className="App">
            {isAllowedDo ? (
                <>
                    <Header />
                    <Routes key={location.pathname} location={location}>
                        <Route element={<Register />} path="/register" />
                        <Route element={<Login />} path="/login" />
                        <Route element={<Download />} path="/download" />
                        <Route element={<Promotion />} path="/promotion" />
                        <Route element={<Home />} path="/" />
                        <Route element={<Lottery />} path="/lottery" />
                        <Route element={<Fishing />} path="/fishing" />
                        <Route element={<Casino />} path="/casino" />
                        <Route element={<Pvp />} path="/pvp" />
                        <Route element={<Sports />} path="/sports" />
                        <Route element={<Slot />} path="/slots" />
                        <Route path="/" element={<PrivateRoute />}>
                            {/* Xổ số miền bắc */}
                            <Route element={<MBDedb />} path="/xsmb/dedb" />
                            <Route element={<MBDedaudb />} path="/xsmb/dedaudb" />
                            <Route element={<MBDe1 />} path="/xsmb/de1" />
                            <Route element={<MBDedau1 />} path="/xsmb/dedau1" />
                            <Route element={<MBDegiai7 />} path="/xsmb/degiai7" />
                            <Route element={<MBLo2 />} path="/xsmb/lo2" />
                            <Route element={<MBLo2so1k />} path="/xsmb/lo2so1k" />
                            <Route element={<MBLo2dau />} path="/xsmb/lo2dau" />
                            <Route element={<MBLo3 />} path="/xsmb/lo3" />
                            <Route element={<MBLo4 />} path="/xsmb/lo4" />
                            <Route element={<MBLoxien2 />} path="/xsmb/loxien2" />
                            <Route element={<MBLoxien3 />} path="/xsmb/loxien3" />
                            <Route element={<MBLoxien4 />} path="/xsmb/loxien4" />
                            <Route element={<MB3cangdb />} path="/xsmb/3cangdb" />
                            <Route element={<MB3cang1 />} path="/xsmb/3cang1" />
                            <Route element={<MB3cangdau />} path="/xsmb/3cangdau" />
                            <Route element={<MB3cangdauduoi />} path="/xsmb/3cangdauduoi" />
                            <Route element={<MBTruotxien4 />} path="/xsmb/truotxien4" />
                            <Route element={<MBTruotxien8 />} path="/xsmb/truotxien8" />
                            <Route element={<MBTruotxien10 />} path="/xsmb/truotxien10" />
                            <Route element={<MB4cangdb />} path="/xsmb/4cangdb" />
                            <Route element={<MBdau />} path="/xsmb/dau" />
                            <Route element={<MBduoi />} path="/xsmb/duoi" />
                            {/* Xổ số miền nam */}
                            <Route element={<MNDedb />} path="/xsmn/dedb/:id" />
                            <Route element={<MNDedaudb />} path="/xsmn/dedaudb/:id" />
                            <Route element={<MNDe1 />} path="/xsmn/de1/:id" />
                            <Route element={<MNDedau1 />} path="/xsmn/dedau1/:id" />
                            <Route element={<MNDegiai7 />} path="/xsmn/degiai7/:id" />
                            <Route element={<MNDedauduoi />} path="/xsmn/dedauduoi/:id" />
                            <Route element={<MNLo2 />} path="/xsmn/lo2/:id" />
                            <Route element={<MNLo2so1k />} path="/xsmn/lo2so1k/:id" />
                            <Route element={<MNLo2dau />} path="/xsmn/lo2dau/:id" />
                            <Route element={<MNLo3 />} path="/xsmn/lo3/:id" />
                            <Route element={<MNLo4 />} path="/xsmn/lo4/:id" />
                            <Route element={<MNLoxien2 />} path="/xsmn/loxien2/:id" />
                            <Route element={<MNLoxien3 />} path="/xsmn/loxien3/:id" />
                            <Route element={<MNLoxien4 />} path="/xsmn/loxien4/:id" />
                            <Route element={<MN3cangdb />} path="/xsmn/3cangdb/:id" />
                            <Route element={<MN3cang1 />} path="/xsmn/3cang1/:id" />
                            <Route element={<MN3cangdau />} path="/xsmn/3cangdau/:id" />
                            <Route element={<MN3cangdauduoi />} path="/xsmn/3cangdauduoi/:id" />
                            <Route element={<MNTruotxien4 />} path="/xsmn/truotxien4/:id" />
                            <Route element={<MNTruotxien8 />} path="/xsmn/truotxien8/:id" />
                            <Route element={<MNTruotxien10 />} path="/xsmn/truotxien10/:id" />
                            <Route element={<MN4cangdb />} path="/xsmn/4cangdb/:id" />
                            <Route element={<MNdau />} path="/xsmn/dau/:id" />
                            <Route element={<MNduoi />} path="/xsmn/duoi/:id" />
                            {/* Xổ số miền trung */}
                            <Route element={<MTDedb />} path="/xsmt/dedb/:id" />
                            <Route element={<MTDedaudb />} path="/xsmt/dedaudb/:id" />
                            <Route element={<MTDe1 />} path="/xsmt/de1/:id" />
                            <Route element={<MTDedau1 />} path="/xsmt/dedau1/:id" />
                            <Route element={<MTDegiai7 />} path="/xsmt/degiai7/:id" />
                            <Route element={<MTDedauduoi />} path="/xsmt/dedauduoi/:id" />
                            <Route element={<MTLo2 />} path="/xsmt/lo2/:id" />
                            <Route element={<MTLo2so1k />} path="/xsmt/lo2so1k/:id" />
                            <Route element={<MTLo2dau />} path="/xsmt/lo2dau/:id" />
                            <Route element={<MTLo3 />} path="/xsmt/lo3/:id" />
                            <Route element={<MTLo4 />} path="/xsmt/lo4/:id" />
                            <Route element={<MTLoxien2 />} path="/xsmt/loxien2/:id" />
                            <Route element={<MTLoxien3 />} path="/xsmt/loxien3/:id" />
                            <Route element={<MTLoxien4 />} path="/xsmt/loxien4/:id" />
                            <Route element={<MT3cangdb />} path="/xsmt/3cangdb/:id" />
                            <Route element={<MT3cang1 />} path="/xsmt/3cang1/:id" />
                            <Route element={<MT3cangdau />} path="/xsmt/3cangdau/:id" />
                            <Route element={<MT3cangdauduoi />} path="/xsmt/3cangdauduoi/:id" />
                            <Route element={<MTTruotxien4 />} path="/xsmt/truotxien4/:id" />
                            <Route element={<MTTruotxien8 />} path="/xsmt/truotxien8/:id" />
                            <Route element={<MTTruotxien10 />} path="/xsmt/truotxien10/:id" />
                            <Route element={<MT4cangdb />} path="/xsmt/4cangdb/:id" />
                            <Route element={<MTdau />} path="/xsmt/dau/:id" />
                            <Route element={<MTduoi />} path="/xsmt/duoi/:id" />
                        </Route>
                    </Routes>
                    <Footer />
                </>
            ) : null}
        </div>
    );
}

export default App;
