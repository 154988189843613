import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./history.css";

function HistoryRecharge() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [payment, setPayment] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                setPayment(res.data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <>
            <div className="historyrecharge">
                <div className="top_title">
                    <div className="txt">Lịch sử nạp tiền</div>
                </div>
                {payment ? (
                    <div className="main-history">
                        <div className="header-history">
                            <div style={{ width: "15%" }}>Loại giao dịch</div>
                            <div style={{ width: "15%" }}>Thời gian giao dịch</div>
                            <div style={{ width: "15%" }}>Số tiền giao dịch</div>
                            <div style={{ width: "15%" }}>Mã giao dịch</div>
                            <div style={{ width: "15%" }}>Trạng thái</div>
                            <div style={{ width: "25%" }}>Nội dung giao dịch</div>
                        </div>
                        {payment?.map((item, key) => (
                            <>
                                {item?.type_payment === "NẠP" && (
                                    <>
                                        <div className="content-history">
                                            <div style={{ width: "15%" }}>{item?.type_payment}</div>
                                            <div style={{ width: "15%" }}>{formatDate(new Date(item?.createdAt))}</div>
                                            <div style={{ width: "15%", color: "red" }}>{Math.floor(item?.money).toLocaleString()}</div>
                                            <div style={{ width: "15%" }}>{item?._id.substring(0, 10)}</div>
                                            <div style={{ width: "15%" }}>
                                                <span
                                                    className={`text_status ${
                                                        item?.status_payment === "Pending" ? "pending" : item?.status_payment === "Success" ? "win" : "lose"
                                                    }`}>
                                                    {item?.status_payment === "Pending" ? "Chờ xử lý" : item?.status_payment === "Success" ? "Thành công" : "Thất bại"}
                                                </span>
                                            </div>
                                            <div style={{ width: "25%", textAlign: "left", paddingLeft: "40px" }}>
                                                Phương thức : {item?.detail}
                                                <br />
                                                Khuyến mãi: {item?.km ? Number(Math.floor(item?.km)).toLocaleString() : 0}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
}
export default HistoryRecharge;
